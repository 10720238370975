import Image from 'next/image'
import Link from 'next/link'

const Landing = _ => {
  return <div className='w-screen h-screen flex items-center justify-center'>
    <Image src='/images/atrium.png' layout='fill' quality='90' objectFit='cover'/>
    <Link href='/'>
      <div className='flex font-display text-center font-semibold tracking-widest items-center justify-center text-white rounded-full w-56 h-56 p-10 transition-all duration-50 ease-in-out hover:w-28 hover:h-28 glow-blue-100 cursor-pointer bg-red-400 z-50' >This is not the page you're looking for</div>
    </Link>
  </div>
}

export default Landing 